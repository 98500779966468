<template>
  <div class="container">
    <i :class="icon" size="40"></i>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 2rem;
  width: 100%;
  background: var(--secondary);
  color: var(--white);
}

i {
  margin-right: 1rem;
  font-size: 1rem;
  color: var(--white);
}
</style>
