<template>
  <main>
    <PageHeader :items="breadcrumb" />
    <ModalViewCommentOld title="Comentário do especialista" />

    <b-row>
      <b-col>
        <b-card>
          <TitleAndDescriptionScreen
            title="Efetivação dos produtos -"
            :customerName="diagnosis ? diagnosis.pessoa.pessoa_nome : ''"
            :diagnosis="diagnosis"
            description="Confira abaixo a lista de documentos e informações necessárias para você completar a venda e efetivar os produtos apresentados ao seu cliente."
            v-if="diagnosis"
          />

          <b-row>
            <HeaderInformation
              icon="fas fa-file-alt"
              title="DADOS COMPLEMENTARES - para ativação dos produtos"
            />

            <b-col
              md="12"
              style="margin-top: 2rem; border-right: 0.5px solid #e7ebf0"
              v-if="customer"
            >
              <b-form-row class="align-items-end">
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>INFORMAÇÕES DO CLIENTE</span>
                  </div>
                </b-col>

                <b-form-group
                  label="Qual o seu nome completo?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-name-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nome_completo'
                    )
                  "
                >
                  <b-form-input
                    placeholder="Nome completo"
                    v-model="customer.pessoa.pessoa_nome"
                    id="customer-profile-name-input"
                    name="customer-profile-name-input"
                    :state="errors.pessoa_nome ? false : null"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_nome">
                    {{ errors.pessoa_nome.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual a sua data de nascimento?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-birth-date-input"
                  :state="errors.pessoa_data_nascimento ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'data_nascimento'
                    )
                  "
                >
                  <b-form-input
                    v-model="customer.pessoa.pessoa_data_nascimento"
                    type="date"
                    id="customer-profile-birth-date-input"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_data_nascimento">
                    {{ errors.pessoa_data_nascimento.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Sexo"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-gender-input"
                  :state="errors.pessoa_sexo ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('sexo')
                  "
                >
                  <b-form-select
                    id="customer-profile-gender-input"
                    v-model="customer.pessoa.pessoa_sexo"
                    :options="sexOptions"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_sexo">
                    {{ errors.pessoa_sexo.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual sua profissão?"
                  class="col-12 col-md-6 col-xl-6"
                  label-for="customer-profile-occupation-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'profissao'
                    )
                  "
                >
                  <model-select
                    id="customer-profile-occupation-input"
                    v-model="customer.pessoa.pessoa_profissao"
                    :options="occupationOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual seu CPF?"
                  class="col-12 col-md-6 col-xl-6"
                  label-for="customer-profile-cpf-input"
                  v-if="diagnosis.diagnostico_campos_fechamento.includes('cpf')"
                >
                  <b-form-input
                    id="customer-profile-cpf-input"
                    v-mask="'###.###.###-##'"
                    type="text"
                    v-model="customer.pessoa.pessoa_cpf"
                    placeholder="CPF"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual o tipo do seu documento de identificação?"
                  class="col-12 col-md-6 col-lg-4"
                  v-if="diagnosis.diagnostico_campos_fechamento.includes('rg')"
                >
                  <b-form-select
                    v-model="customer.pessoa.tipo_documento_identificacao"
                    :options="tipoDocumentoIdentificacaoOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual seu documento de identificação?"
                  class="col-12 col-md-6 col-lg-4"
                  label-for="customer-profile-documento-identificacao-input"
                >
                  <b-form-input
                    id="customer-profile-documento-identificacao-input"
                    v-model="customer.pessoa.documento_identificacao"
                    type="text"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual a data de expedição?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-expedition-date-input"
                  :state="errors.pessoa_data_expedicao ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'data_expedicao'
                    )
                  "
                >
                  <b-form-input
                    v-model="customer.pessoa.pessoa_data_expedicao"
                    type="date"
                    id="customer-profile-expedition-date-input"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_data_expedicao">
                    {{ errors.pessoa_data_expedicao.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual o orgão expedidor?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-orgao-expedidor-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'orgao_expedidor'
                    )
                  "
                >
                  <b-form-input
                    placeholder="Orgão expedidor"
                    v-model="customer.pessoa.pessoa_orgao_expedidor"
                    id="customer-profile-orgao-expedidor-input"
                    :state="errors.pessoa_orgao_expedidor ? false : null"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_orgao_expedidor">
                    {{ errors.pessoa_orgao_expedidor.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual seu CNPJ?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-cnpj-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('cnpj')
                  "
                >
                  <b-form-input
                    id="customer-profile-cnpj-input"
                    v-mask="'##.###.###/####-##'"
                    type="text"
                    v-model="customer.pessoa.pessoa_cnpj"
                    placeholder="CNPJ"
                  />
                </b-form-group>

                <b-form-group
                  label="E-mail"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-email-input"
                  :state="errors.pessoa_email ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('email')
                  "
                >
                  <b-form-input
                    id="customer-profile-email-input"
                    type="text"
                    v-model="customer.pessoa.pessoa_email"
                    placeholder="E-mail"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_email">
                    {{ errors.pessoa_email.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Telefone"
                  class="col-12 col-md-6 col-xl-6"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('telefone')
                  "
                >
                  <VuePhoneNumberInput
                    :translations="numberInputTranslations"
                    v-model="customer.pessoa.pessoa_telefone"
                  />
                </b-form-group>

                <b-form-group
                  label="Estado civil?"
                  class="col-12 col-md-6 col-xl-6"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'estado_civil'
                    )
                  "
                >
                  <b-form-select
                    v-model="customer.pessoa.pessoa_estado_civil"
                    :options="estadoCivilOptions"
                    :state="errors.pessoa_estado_civil ? false : null"
                    id="customer-profile-marital-status-feedback"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_estado_civil">
                    {{ errors.pessoa_estado_civil.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Nacionalidade"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-email-input"
                  :state="errors.pessoa_nacionalidade ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nacionalidade'
                    )
                  "
                >
                  <b-form-input
                    id="customer-profile-nacionalidade-input"
                    type="text"
                    v-model="customer.pessoa.pessoa_nacionalidade"
                    placeholder="Nacionalidade"
                  />

                  <b-form-invalid-feedback v-if="errors.pessoa_nacionalidade">
                    {{ errors.pessoa_nacionalidade.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Nome da instituição que trabalha"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-instituicao_trabalho-input"
                  :state="errors.pessoa_instituicao_trabalho ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nome_instituicao_trabalha'
                    )
                  "
                >
                  <b-form-input
                    id="customer-profile-instituicao_trabalho-input"
                    type="text"
                    v-model="customer.pessoa.pessoa_instituicao_trabalho"
                    placeholder="Nome da instituição que trabalha"
                  />

                  <b-form-invalid-feedback
                    v-if="errors.pessoa_instituicao_trabalho"
                  >
                    {{ errors.pessoa_instituicao_trabalho.join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual o seu salário/renda bruta mensal?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="income-salario-bruto-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'receita_bruta_anual'
                    )
                  "
                >
                  <money
                    class="form-control"
                    id="income-salario-bruto-input"
                    v-bind="money"
                    v-model="
                      customer.pessoa.pessoa_renda.pessoa_renda_salario_bruto
                    "
                  />
                </b-form-group>

                <b-form-group
                  label="Qual sua altura?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-altura-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('altura')
                  "
                >
                  <money
                    id="health-expenditure-risk-altura-input"
                    class="form-control"
                    v-bind="height"
                    v-model="customer.pessoa.pessoa_saude.altura"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual seu peso?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-peso-input"
                  type="number"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('peso')
                  "
                >
                  <money
                    id="health-expenditure-risk-peso-input"
                    class="form-control"
                    v-bind="weight"
                    v-model="customer.pessoa.pessoa_saude.peso"
                  />
                </b-form-group>

                <b-form-group
                  label="Fumante?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-fumante-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('fumante')
                  "
                >
                  <b-form-select
                    id="health-expenditure-risk-fumante-input"
                    v-model="customer.pessoa.pessoa_saude.fumante"
                    :options="fumanteOptions"
                  />
                </b-form-group>
              </b-form-row>

              <b-form-row
                class="align-items-end"
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'endereco_completo'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>ENDEREÇO</span>
                  </div>
                </b-col>

                <b-form-group
                  label="CEP"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="customer-profile-address-address-cep"
                >
                  <b-form-input
                    type="text"
                    placeholder="CEP"
                    v-mask="'#####-###'"
                    @input="
                      searchAddress(customer.pessoa.endereco.endereco_cep)
                    "
                    id="customer-profile-address-address-cep"
                    v-model="customer.pessoa.endereco.endereco_cep"
                    :state="errors['endereco.endereco_cep'] ? false : null"
                  />

                  <b-form-invalid-feedback
                    v-if="errors['endereco.endereco_cep']"
                  >
                    {{ errors['endereco.endereco_cep'].join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <template v-if="customer.pessoa.endereco.endereco_cidade">
                  <b-form-group
                    label="Bairro"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-district-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Bairro"
                      id="customer-profile-address-district-input"
                      v-model="customer.pessoa.endereco.endereco_bairro"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Rua"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-address-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Rua"
                      id="customer-profile-address-address-input"
                      v-model="customer.pessoa.endereco.endereco_logradouro"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Número"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-number-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Número"
                      v-mask="'#####'"
                      id="customer-profile-address-number-input"
                      v-model="customer.pessoa.endereco.endereco_numero"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Estado"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-state-input"
                  >
                    <b-form-select
                      id="customer-profile-address-state-input"
                      v-model="customer.pessoa.endereco.endereco_estado"
                      :options="stateOptions"
                      :state="errors['endereco.endereco_estado'] ? false : null"
                    />

                    <b-form-invalid-feedback
                      v-if="errors['endereco.endereco_estado']"
                    >
                      {{ errors['endereco.endereco_estado'].join(', ') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    label="Cidade"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-city-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Cidade"
                      id="customer-profile-address-city-input"
                      v-model="customer.pessoa.endereco.endereco_cidade"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Complemento"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-complement-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Complemento"
                      id="customer-profile-address-complement-input"
                      v-model="customer.pessoa.endereco.endereco_complemento"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Residência fiscal"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="customer-profile-address-residencia-fiscal-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Residência fiscal"
                      id="customer-profile-address-residencia-fiscal-input"
                      v-model="
                        customer.pessoa.endereco.endereco_residencia_fiscal
                      "
                    />
                  </b-form-group>
                </template>
              </b-form-row>

              <b-form-row
                class="align-items-end"
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'preenchimento_dps'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>PREENCHIMENTO DPS</span>
                  </div>
                </b-col>

                <b-form-group
                  label="Você possui alguma rotina que aumente a sua exposição ao risco?"
                  :class="`col-12 ${
                    customer.pessoa.pessoa_plano
                      .planob_possui_rotina_exposicao_risco
                      ? 'col-md-6'
                      : ''
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_plano
                        .planob_possui_rotina_exposicao_risco
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual?"
                  class="col-12 col-md-6"
                  label-for="planb-qual-risco-input"
                  v-show="
                    customer.pessoa.pessoa_plano
                      .planob_possui_rotina_exposicao_risco
                  "
                >
                  <b-form-input
                    class="form-control"
                    id="planb-qual-risco-input"
                    type="text"
                    v-model="
                      customer.pessoa.pessoa_plano.planob_rotina_exposicao_risco
                    "
                  />
                </b-form-group>

                <b-form-group
                  label="Você possui motocicleta?"
                  :class="`col-12 ${
                    customer.pessoa.pessoa_plano.planob_possui_motocicleta
                      ? 'col-md-6 col-lg-4'
                      : ''
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_plano.planob_possui_motocicleta
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual cilindrada?"
                  class="col-12 col-md-6 col-lg-4"
                  label-for="planb-cilindrada-input"
                  v-show="
                    customer.pessoa.pessoa_plano.planob_possui_motocicleta
                  "
                >
                  <b-form-select
                    id="planb-cilindrada-input"
                    v-model="
                      customer.pessoa.pessoa_plano.planob_motocicleta_cilindrada
                    "
                    :options="cilindradaOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Utiliza para qual finalidade?"
                  class="col-12 col-md-6 col-lg-4"
                  label-for="planb-finalidade-input"
                  v-show="
                    customer.pessoa.pessoa_plano.planob_possui_motocicleta
                  "
                >
                  <b-form-input
                    class="form-control"
                    id="planb-finalidade-input"
                    type="text"
                    v-model="
                      customer.pessoa.pessoa_plano.planob_moticicleta_finalidade
                    "
                  />
                </b-form-group>

                <b-form-group
                  label="Toma algum remédio controlado?"
                  :class="`col-12 col-md-6 col-lg-${
                    customer.pessoa.pessoa_saude.toma_remedio_controlado
                      ? '4'
                      : '12'
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_saude.toma_remedio_controlado
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-col
                  cols="12"
                  v-show="customer.pessoa.pessoa_saude.toma_remedio_controlado"
                >
                  <b-form-row
                    v-for="(remedio, index) in customer.pessoa.pessoa_remedios"
                    :key="index"
                  >
                    <b-form-group
                      label="Qual o nome?"
                      class="col-12 col-md-5"
                      label-for="health-expenditure-risk-remedio_controlado"
                      v-show="
                        customer.pessoa.pessoa_saude.toma_remedio_controlado
                      "
                    >
                      <b-form-input
                        class="form-control"
                        v-model="remedio.nome"
                        type="text"
                        id="health-expenditure-risk-remedio_controlado"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Qual a dosagem?"
                      class="col-12 col-md-4 col-lg-5"
                      label-for="health-expenditure-risk-dosagem_remedio_controlado"
                      v-show="
                        customer.pessoa.pessoa_saude.toma_remedio_controlado
                      "
                    >
                      <b-form-input
                        class="form-control"
                        v-model="remedio.dosagem"
                        type="text"
                        id="health-expenditure-risk-dosagem_remedio_controlado"
                      />
                    </b-form-group>

                    <b-col md="3" lg="2" class="text-right">
                      <b-button
                        pill
                        variant="danger"
                        @click="() => handleDeleteRemedio(index)"
                      >
                        <i class="fas fa-trash-alt" />
                      </b-button>
                    </b-col>
                  </b-form-row>

                  <b-button-group class="mt-3">
                    <b-button @click="() => addRemedio()" variant="primary">
                      ADICIONAR REMÉDIO
                    </b-button>

                    <b-button
                      @click="() => addRemedio()"
                      variant="dark-primary"
                    >
                      <i class="bx bx-plus" style="font-size: 0.95rem"></i>
                    </b-button>
                  </b-button-group>
                </b-col>

                <hr style="width: 100%" />

                <b-form-group
                  label="Tem alguma previsão de cirurgia futura?"
                  :class="`col-12 ${
                    customer.pessoa.pessoa_saude.possui_previsao_cirurgia
                      ? 'col-md-6 col-lg-4'
                      : ''
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_saude.possui_previsao_cirurgia
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  class="col-12"
                  label-for="health-expenditure-risk-ate_quando-input"
                  v-show="customer.pessoa.pessoa_saude.possui_previsao_cirurgia"
                >
                  <p class="mb-0">Quais cirúrgias?</p>

                  <b-form-textarea
                    id="health-expenditure-risk-ate_quando-input"
                    rows="5"
                    v-model="customer.pessoa.pessoa_saude.previsao_cirurgia"
                  />
                </b-form-group>

                <hr style="width: 100%" />

                <b-form-group
                  label="Algum histórico de doença na familia (pais/irmãos)?"
                  :class="`col-12 ${
                    customer.pessoa.pessoa_saude.possui_historico_doenca_familia
                      ? 'col-md-6 col-lg-4'
                      : ''
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_saude
                        .possui_historico_doenca_familia
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  class="col-12"
                  label-for="health-expenditure-risk-ate_quando-input"
                  v-show="
                    customer.pessoa.pessoa_saude.possui_historico_doenca_familia
                  "
                >
                  <p class="mb-0">Quais doenças?</p>

                  <b-form-textarea
                    id="health-expenditure-risk-ate_quando-input"
                    rows="5"
                    v-model="
                      customer.pessoa.pessoa_saude.historico_doenca_familia
                    "
                  />
                </b-form-group>

                <hr style="width: 100%" />

                <b-form-group
                  label="Tem algum histórico de doenças pré-existentes?"
                  :class="`col-12 col-md-6 col-lg-${
                    customer.pessoa.pessoa_saude
                      .possui_historico_doenca_preexistente
                      ? '4'
                      : '12'
                  }`"
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_saude
                        .possui_historico_doenca_preexistente
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  class="col-12"
                  label-for="health-expenditure-risk-doencas_pre_existentes-input"
                  v-show="
                    customer.pessoa.pessoa_saude
                      .possui_historico_doenca_preexistente
                  "
                >
                  <p class="mb-0">Se sim, quais doenças pré-existentes?</p>

                  <b-form-textarea
                    id="health-expenditure-risk-doencas_pre_existentes-input"
                    rows="5"
                    v-model="
                      customer.pessoa.pessoa_saude.historico_doenca_preexistente
                    "
                  />
                </b-form-group>

                <hr style="width: 100%" />

                <b-form-group
                  label="Você tem algum exame fora da normalidade?"
                  class="col-12 col-md-6 col-lg-4"
                >
                  <b-form-radio-group
                    v-model="customer.pessoa.pessoa_saude.possui_exame_anormal"
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  class="col-12"
                  label-for="health-expenditure-risk-exame_fora_da_normalidade-input"
                  v-show="customer.pessoa.pessoa_saude.possui_exame_anormal"
                >
                  <p class="mb-0">Quais?</p>

                  <b-form-textarea
                    id="health-expenditure-risk-exame_fora_da_normalidade-input"
                    rows="5"
                    v-model="customer.pessoa.pessoa_saude.exame_anormal"
                  />
                </b-form-group>
              </b-form-row>

              <b-form-row
                class="align-items-end"
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_pagamento'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>PAGAMENTO</span>
                  </div>
                </b-col>

                <b-form-group
                  label="Forma de pagamento"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-fumante-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'forma_pagamento'
                    )
                  "
                >
                  <b-form-select
                    id="health-expenditure-risk-fumante-input"
                    v-model="customer.pessoa.pessoa_pagamento.forma_pagamento"
                    :options="formaPagamentoOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Periodicidade?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-fumante-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'periodicidade_pagamento'
                    )
                  "
                >
                  <b-form-select
                    id="health-expenditure-risk-fumante-input"
                    v-model="
                      customer.pessoa.pessoa_pagamento.periodicidade_pagamento
                    "
                    :options="periodicidadePagamentoOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Responsável pelo pagamento diferente do segurado?"
                  class="col-12"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    )
                  "
                >
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_pagamento
                        .responsavel_pagamento_diferente
                    "
                    :options="yesOrNotOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual o nome completo?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-name-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    placeholder="Nome completo"
                    v-model="customer.pessoa.pessoa_pagamento.nome"
                    id="responsavel-name-input"
                    :state="errors['pessoa_pagamento.nome'] ? false : null"
                  />

                  <b-form-invalid-feedback
                    v-if="errors['pessoa_pagamento.nome']"
                  >
                    {{ errors['pessoa_pagamento.nome'].join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual a profissão?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-occupation-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <model-select
                    id="responsavel-occupation-input"
                    v-model="customer.pessoa.pessoa_pagamento.profissao"
                    :options="occupationOptions"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual o CPF?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-cpf-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    id="responsavel-cpf-input"
                    v-mask="'###.###.###-##'"
                    type="text"
                    v-model="customer.pessoa.pessoa_pagamento.cpf"
                    placeholder="CPF"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual o RG?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-rg-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    id="responsavel-rg-input"
                    v-mask="'##.###.###-#'"
                    v-model="customer.pessoa.pessoa_pagamento.rg"
                    type="text"
                    placeholder="RG"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual o CNPJ?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-cnpj-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    id="responsavel-cnpj-input"
                    v-mask="'##.###.###/####-##'"
                    type="text"
                    v-model="customer.pessoa.pessoa_pagamento.cnpj"
                    placeholder="CNPJ"
                  />
                </b-form-group>

                <b-form-group
                  label="E-mail"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-email-input"
                  :state="errors['pessoa_pagamento.email'] ? false : null"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    id="responsavel-email-input"
                    type="text"
                    v-model="customer.pessoa.pessoa_pagamento.email"
                    placeholder="E-mail"
                  />

                  <b-form-invalid-feedback
                    v-if="errors['pessoa_pagamento.email']"
                  >
                    {{ errors['pessoa_pagamento.email'].join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Telefone"
                  class="col-12 col-md-6 col-xl-4"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <VuePhoneNumberInput
                    :translations="numberInputTranslations"
                    v-model="customer.pessoa.pessoa_pagamento.telefone"
                  />
                </b-form-group>

                <b-form-group
                  label="Nome da instituição que trabalha"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="responsavel-instituicao_trabalho-input"
                  :state="
                    errors['pessoa_pagamento.instituicao_trabalho']
                      ? false
                      : null
                  "
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    id="responsavel-instituicao_trabalho-input"
                    type="text"
                    v-model="
                      customer.pessoa.pessoa_pagamento.instituicao_trabalho
                    "
                    placeholder="Nome da instituição que trabalha"
                  />

                  <b-form-invalid-feedback
                    v-if="errors['pessoa_pagamento.instituicao_trabalho']"
                  >
                    {{
                      errors['pessoa_pagamento.instituicao_trabalho'].join(', ')
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Qual o salário/renda bruta anual?"
                  class="col-12 col-md-6 col-xl-4"
                  label-for="income-salario-bruto-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <money
                    class="form-control"
                    id="income-salario-bruto-input"
                    v-bind="money"
                    v-model="
                      customer.pessoa.pessoa_pagamento.receita_bruta_anual
                    "
                  />
                </b-form-group>

                <b-form-group
                  label="CEP"
                  class="col-12 col-xl-4"
                  label-for="responsavel-address-address-cep"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-input
                    type="text"
                    placeholder="CEP"
                    v-mask="'#####-###'"
                    @input="
                      searchAddressResponsavelPagamento(
                        customer.pessoa.pessoa_pagamento.endereco.endereco_cep
                      )
                    "
                    id="responsavel-address-address-cep"
                    v-model="
                      customer.pessoa.pessoa_pagamento.endereco.endereco_cep
                    "
                    :state="errors['endereco.endereco_cep'] ? false : null"
                  />

                  <b-form-invalid-feedback
                    v-if="errors['endereco.endereco_cep']"
                  >
                    {{ errors['endereco.endereco_cep'].join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <template
                  v-if="
                    customer.pessoa.pessoa_pagamento.endereco.endereco_cidade &&
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'responsavel_pagamento_diferente_segurado'
                    ) &&
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <b-form-group
                    label="Bairro"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-district-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Bairro"
                      id="responsavel-address-district-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_bairro
                      "
                    />
                  </b-form-group>

                  <b-form-group
                    label="Rua"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-address-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Rua"
                      id="responsavel-address-address-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_logradouro
                      "
                    />
                  </b-form-group>

                  <b-form-group
                    label="Número"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-number-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Número"
                      v-mask="'#####'"
                      id="responsavel-address-number-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_numero
                      "
                    />
                  </b-form-group>

                  <b-form-group
                    label="Estado"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-state-input"
                  >
                    <b-form-select
                      id="responsavel-address-state-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_estado
                      "
                      :options="stateOptions"
                      :state="errors['endereco.endereco_estado'] ? false : null"
                    />

                    <b-form-invalid-feedback
                      v-if="errors['endereco.endereco_estado']"
                    >
                      {{ errors['endereco.endereco_estado'].join(', ') }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    label="Cidade"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-city-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Cidade"
                      id="responsavel-address-city-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_cidade
                      "
                    />
                  </b-form-group>

                  <b-form-group
                    label="Complemento"
                    class="col-12 col-md-6 col-xl-4"
                    label-for="responsavel-address-complement-input"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Complemento"
                      id="responsavel-address-complement-input"
                      v-model="
                        customer.pessoa.pessoa_pagamento.endereco
                          .endereco_complemento
                      "
                    />
                  </b-form-group>
                </template>
              </b-form-row>

              <b-form-row>
                <b-form-group
                  label="Qual o seu salário/renda bruta mensal?"
                  class="col-12 col-md-6 col-xl-5"
                  label-for="income-salario-bruto-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'receita_bruta_anual'
                    )
                  "
                >
                  <money
                    class="form-control"
                    id="income-salario-bruto-input"
                    v-bind="money"
                    v-model="
                      customer.pessoa.pessoa_renda.pessoa_renda_salario_bruto
                    "
                  />
                </b-form-group>

                <b-form-group
                  label="Qual sua altura?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-altura-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('altura')
                  "
                >
                  <money
                    id="health-expenditure-risk-altura-input"
                    class="form-control"
                    v-bind="height"
                    v-model="customer.pessoa.pessoa_saude.altura"
                  />
                </b-form-group>

                <b-form-group
                  label="Qual seu peso?"
                  class="col-12 col-md-6 col-xl-2"
                  label-for="health-expenditure-risk-peso-input"
                  type="number"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('peso')
                  "
                >
                  <money
                    id="health-expenditure-risk-peso-input"
                    class="form-control"
                    v-bind="weight"
                    v-model="customer.pessoa.pessoa_saude.peso"
                  />
                </b-form-group>

                <b-form-group
                  label="Fumante?"
                  class="col-12 col-md-6 col-xl-3"
                  label-for="health-expenditure-risk-fumante-input"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('fumante')
                  "
                >
                  <b-form-select
                    id="health-expenditure-risk-fumante-input"
                    v-model="customer.pessoa.pessoa_saude.fumante"
                    :options="fumanteOptions"
                  />
                </b-form-group>
              </b-form-row>

              <div
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_dependentes'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>DEPENDENTES</span>
                  </div>
                </b-col>

                <div
                  v-for="(dependent, index) in customer.pessoa.dependentes"
                  :key="index"
                >
                  <p class="d-flex align-items-center">
                    {{ index + 1 }}. DEPENDENTE
                    <i
                      v-b-tooltip.hover
                      title="Incluir apenas na contratação de seguro saúde e quando há dependentes"
                      class="bx bx-help-circle ml-2"
                      style="font-size: 1.2rem"
                    />
                  </p>

                  <b-form-row class="align-items-end">
                    <b-form-group
                      label="Nome"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-dependent-${index}-name-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_dependentes'
                        )
                      "
                    >
                      <b-form-input
                        v-model="dependent.nome_dependente"
                        :id="`family-profile-dependent-${index}-name-input`"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Data de nascimento"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-dependent-${index}-birth-date-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_dependentes'
                        )
                      "
                    >
                      <b-form-input
                        v-model="dependent.data_nasc_dependente"
                        type="date"
                        :id="`family-profile-dependent-${index}-birth-date-input`"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Grau de parentesco"
                      class="col-12 col-md-6 col-xl-4"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'parentesco_dependentes'
                        )
                      "
                    >
                      <b-form-select
                        v-model="dependent.grau_parentesco_dependente"
                        :options="parentescoOptions"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="dependent.grau_parentesco_dependente === 'Outro'"
                      label="Qual outro grau de parentesco?"
                      class="col-12 col-md-6 col-xl-4"
                    >
                      <b-form-input value="" />
                    </b-form-group>

                    <b-form-group
                      label="CPF"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-dependent-${index}-cpf-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'cpf_dependentes'
                        )
                      "
                    >
                      <b-form-input
                        :id="`family-profile-dependent-${index}-cpf-input`"
                        v-mask="'###.###.###-##'"
                        type="text"
                        v-model="dependent.cpf_dependente"
                        placeholder="CPF"
                      />
                    </b-form-group>

                    <b-form-group
                      label="RG"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-dependent-${index}-rg-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'rg_dependentes'
                        )
                      "
                    >
                      <b-form-input
                        :id="`family-profile-dependent-${index}-rg-input`"
                        v-mask="'##.###.###-#'"
                        v-model="dependent.rg_dependente"
                        type="text"
                        placeholder="RG"
                      />
                    </b-form-group>

                    <b-col md="6" lg="4" class="text-right mb-3">
                      <b-button
                        pill
                        variant="danger"
                        @click="() => handleDeleteDependent(index)"
                      >
                        <i class="fas fa-trash-alt" />
                      </b-button>
                    </b-col>
                  </b-form-row>
                  <hr />
                </div>
              </div>

              <b-button-group class="nav-link">
                <b-button
                  style="background: #dddfe3; border-color: #ced4da"
                  :disabled="
                    !customer.pessoa.perfil_familia
                      .perfil_familia_possui_dependentes
                  "
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 0.95rem; color: #495057"
                  />
                </b-button>

                <b-button
                  style="background: #eff2f7; border-color: #ced4da"
                  :disabled="
                    !customer.pessoa.perfil_familia
                      .perfil_familia_possui_dependentes
                  "
                  @click="handleAddDependent()"
                >
                  <span style="color: #495057">Adicionar dependente</span>
                </b-button>
              </b-button-group>

              <div
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_beneficiarios'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span class="d-flex align-items-center">
                      BENEFICIÁRIOS
                      <i
                        v-b-tooltip.hover
                        title="Incluir apenas na contratação de seguro de vida"
                        class="bx bx-help-circle ml-2"
                        style="font-size: 1.2rem"
                      />
                    </span>
                  </div>
                </b-col>

                <div
                  v-for="(beneficiary, index) in customer.pessoa.beneficiarios"
                  :key="index"
                >
                  <p>{{ index + 1 }}. BENEFICIÁRIO</p>

                  <b-form-row class="align-items-end">
                    <b-form-group
                      label="Nome"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-beneficiary-${index}-name-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_beneficiarios'
                        )
                      "
                    >
                      <b-form-input
                        v-model="beneficiary.nome_beneficiario"
                        :id="`family-profile-beneficiary-${index}-name-input`"
                      />
                    </b-form-group>

                    <b-form-group
                      label="CPF"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-beneficiary-${index}-cpf-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'numero_cpf_beneficiarios'
                        )
                      "
                    >
                      <b-form-input
                        :id="`family-profile-beneficiary-${index}-cpf-input`"
                        v-mask="'###.###.###-##'"
                        type="text"
                        v-model="beneficiary.cpf_beneficiario"
                        placeholder="CPF"
                      />
                    </b-form-group>

                    <b-form-group
                      label="RG"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-beneficiary-${index}-rg-input`"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'numero_rg_beneficiarios'
                        )
                      "
                    >
                      <b-form-input
                        :id="`family-profile-beneficiary-${index}-rg-input`"
                        v-mask="'##.###.###-#'"
                        v-model="beneficiary.rg_beneficiario"
                        type="text"
                        placeholder="RG"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Data de nascimento"
                      class="col-12 col-md-6 col-xl-4"
                      :label-for="`family-profile-beneficiary-${index}-birth-date-input`"
                    >
                      <b-form-input
                        v-model="beneficiary.data_nascimento_beneficiario"
                        type="date"
                        :id="`family-profile-beneficiary-${index}-birth-date-input`"
                      />
                    </b-form-group>

                    <b-form-group
                      label="Grau de parentesco"
                      class="col-12 col-md-6 col-xl-4"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'parentesco_beneficiarios'
                        )
                      "
                    >
                      <b-form-select
                        v-model="beneficiary.grau_parentesco_beneficiario"
                        :options="parentescoOptions"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="
                        beneficiary.grau_parentesco_beneficiario === 'Outro'
                      "
                      label="Qual outro grau de parentesco?"
                      class="col-12 col-md-6 col-xl-4"
                    >
                      <b-form-input value="" />
                    </b-form-group>

                    <b-form-group
                      label="Percentual"
                      class="col-12 col-md-6 col-xl-2"
                      :label-for="`family-profile-beneficiary-${index}-percent-input`"
                      type="number"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'percentual_beneficiarios'
                        )
                      "
                    >
                      <money
                        :id="`family-profile-beneficiary-${index}-percent-input`"
                        class="form-control"
                        v-bind="percent"
                        v-model="beneficiary.percentual_beneficiario"
                      />
                    </b-form-group>

                    <b-col lg="2" class="text-right mb-3">
                      <b-button
                        pill
                        variant="danger"
                        @click="() => handleDeleteBeneficiary(index)"
                      >
                        <i class="fas fa-trash-alt" />
                      </b-button>
                    </b-col>
                  </b-form-row>

                  <hr />
                </div>
              </div>

              <b-button-group class="nav-link">
                <b-button style="background: #dddfe3; border-color: #ced4da">
                  <i
                    class="bx bx-plus"
                    style="font-size: 0.95rem; color: #495057"
                  />
                </b-button>

                <b-button
                  style="background: #eff2f7; border-color: #ced4da"
                  @click="handleAddBeneficiary()"
                >
                  <span style="color: #495057">Adicionar beneficiário</span>
                </b-button>
              </b-button-group>
            </b-col>

            <HeaderInformation
              icon="fas fa-paper-plane"
              title="DOCUMENTOS QUE DEVEM SER ENVIADOS"
            />

            <b-col md="12" style="padding: 1rem">
              <div style="margin-top: 1rem">
                <div style="margin-top: 1rem" class="hrdivider">
                  <hr />
                  <span>DOCUMENTOS PENDENTES</span>
                </div>
                <div style="margin-top: 1rem">
                  Faça o upload dos documentos listados abaixo:
                </div>

                <div
                  style="margin: 1rem 0"
                  v-for="document in documentsWaiting"
                  :key="document.diagnostico_documento_id"
                >
                  <div style="margin-bottom: 0px">
                    {{ document.titulo }}

                    <span v-if="document.download_url">
                      - Enviado dia {{ document.data_alteracao | formatDate }}
                    </span>

                    <i
                      style="margin-left: 5px"
                      class="fas fa-exclamation-circle"
                      v-b-tooltip.hover="document.tooltip"
                      v-if="document.tooltip"
                    />

                    <div v-if="document.download_url" class="mb-2">
                      <p class="mb-0">{{ document.nome_original }}</p>

                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <a :href="document.download_url" target="_blank">
                          Visualizar
                        </a>

                        <b-button
                          pill
                          variant="danger"
                          @click="() => handleDeleteFile(document)"
                        >
                          <i class="fas fa-trash-alt" />
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <b-form-file
                    v-model="document.file"
                    :file-name-formatter="formatNames"
                    @input="() => handleFileUpload(document)"
                  />
                </div>
              </div>

              <div
                style="margin-top: 4rem"
                v-show="rejectedDocuments.length > 0"
              >
                <div class="hrdivider">
                  <hr style="background: red" />
                  <p>
                    Por favor, verifique as necessidades de ajustes e encaminhe
                    os documentos corretos
                  </p>
                  <span style="color: #e21b32">DOCUMENTOS RECUSADOS</span>
                </div>

                <div
                  v-for="document in rejectedDocuments"
                  :key="document.diagnostico_documento_id"
                  style="margin: 1rem 0"
                >
                  <div class="d-flex align-items-end justify-content-between">
                    <p
                      style="
                        margin-bottom: 6px;
                        font-size: 10px;
                        color: #e21b32;
                      "
                    >
                      {{ document.titulo }} - Enviado dia
                      {{ document.data_alteracao | formatDate }}
                    </p>

                    <b-button-group
                      style="padding: 6px 0"
                      @click="() => viewSpecialistComment(document)"
                      v-if="
                        document.diagnostico_comentario_especialista &&
                        document.diagnostico_comentario_especialista.comentario
                      "
                    >
                      <b-button
                        style="
                          background: #7392eb;
                          border-color: #7392eb;
                          height: 3rem;
                          width: 3rem;
                        "
                      >
                        <i class="fas fa-eye" style="font-size: 1rem"></i>
                      </b-button>

                      <b-button
                        style="width: 7rem; height: 3rem; font-size: 10px"
                        variant="primary"
                      >
                        Ver comentário do especialista
                      </b-button>
                    </b-button-group>
                  </div>

                  <b-form-file
                    style="border: 1px solid red; border-radius: 5px"
                    :file-name-formatter="formatNames"
                    v-model="document.file"
                  />
                </div>

                <b-button
                  style="width: 100%; margin-top: 1rem"
                  variant="primary"
                  @click="handleRejectedFilesUpload()"
                >
                  ENVIAR ARQUIVOS
                </b-button>
              </div>

              <div
                style="margin-top: 4rem"
                v-show="verifiedDocuments.length > 0"
              >
                <div class="hrdivider">
                  <hr style="background: #078d42" />
                  <span style="color: #078d42">VERIFICADOS</span>
                </div>

                <div
                  v-for="document in verifiedDocuments"
                  :key="document.diagnostico_documento_id"
                  style="margin: 1rem 0"
                >
                  <p style="margin-bottom: 0px; color: #078d42">
                    <strong>{{ document.titulo }}</strong> - Enviado dia
                    {{ document.data_alteracao | formatDate }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>

          <hr />

          <b-col md="12" class="d-flex justify-content-between p-2">
            <b-button @click="handleSendData()" variant="primary">
              ENVIAR TODOS OS DADOS
            </b-button>

            <b-button variant="primary" @click="handleSave()">
              SALVAR E CONTINUAR DEPOIS
            </b-button>
          </b-col>
        </b-card>

        <div v-if="customer">
          <b-card class="diagnosis-info-section">
            <b-card-title class="text-primary">
              <i class="fas fa-user-check"></i>
              <span class="ml-2"
                >Se o meu trabalho te agradou e pode gerar valor a pessoas
                próximas, podemos falar sobre algumas recomendações ?</span
              >
            </b-card-title>
          </b-card>

          <b-card
            v-for="(indication, index) in customer.pessoa.indicacoes"
            :key="index"
            class="diagnosis-info-section"
          >
            <b-card-title
              class="text-primary d-flex align-items-center justify-content-between"
            >
              <span>
                <i class="fas fa-user-check"></i>
                <span class="ml-2">Recomendação - {{ index + 1 }}</span>
              </span>

              <b-button
                pill
                variant="danger"
                @click="() => handleDeleteIndication(index)"
              >
                <i class="fas fa-trash-alt" />
              </b-button>
            </b-card-title>

            <div class="diagnosis-info-content">
              <b-form-row class="align-items-end">
                <b-form-group
                  label="Nome:"
                  class="col-12 col-md-6"
                  :label-for="`priorities-indication-${index}-name-input`"
                >
                  <b-form-input
                    class="form-control"
                    :id="`priorities-indication-${index}-name-input`"
                    type="text"
                    v-model="indication.nome"
                  />
                </b-form-group>

                <b-form-group
                  label="E-mail:"
                  class="col-12 col-md-6"
                  :label-for="`priorities-indication-${index}-email-input`"
                >
                  <b-form-input
                    class="form-control"
                    :id="`priorities-indication-${index}-email-input`"
                    type="email"
                    v-model="indication.email"
                    :state="errors[`indicacoes.${index}.email`] ? false : null"
                  />

                  <b-form-invalid-feedback
                    v-if="errors[`indicacoes.${index}.email`]"
                  >
                    {{ errors[`indicacoes.${index}.email`].join(', ') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="telephone-input-group"
                  label="Telefone"
                  class="col-12 col-md-6 col-lg-6"
                  :label-for="`priorities-indication-${index}-telephone-input`"
                >
                  <VuePhoneNumberInput
                    v-model="indication.telefone"
                    :translations="numberInputTranslations"
                    :id="`priorities-indication-${index}-telephone-input`"
                  />
                </b-form-group>

                <b-form-group
                  label="Número de dependentes:"
                  class="col-12 col-md-6 col-lg-4"
                  :label-for="`priorities-indication-${index}-dependents-input`"
                >
                  <b-form-input
                    class="form-control"
                    :id="`priorities-indication-${index}-dependents-input`"
                    type="number"
                    v-model="indication.numero_dependentes"
                  />
                </b-form-group>
              </b-form-row>
            </div>
          </b-card>

          <div
            class="d-block d-md-flex align-items-center justify-content-between"
          >
            <b-button-group class="mb-3" @click="() => addIndicacao()">
              <b-button variant="primary"> ADICIONAR RECOMENDAÇÃO </b-button>

              <b-button variant="dark-primary">
                <i class="bx bx-plus" style="font-size: 0.95rem"></i>
              </b-button>
            </b-button-group>

            <b-button variant="primary" @click="() => handleSave()">
              SALVAR
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';
import moment from 'moment';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { ModelSelect } from 'vue-search-select';

import PageHeader from '@/components/PageHeader';
import ModalViewCommentOld from '@/components/common/Modals/ModalViewCommentOld';

import TitleAndDescriptionScreen from '@/components/DiagnosisComplete/common/TitleAndDescriptionScreen';
import HeaderInformation from '@/components/DiagnosisComplete/common/HeaderInformation';

import createToastMixin from '@/mixins/create-toast-mixin';
import {
  requestDiagnosis,
  uploadDocumentFile,
  deleteDocumentFile,
  updatePhase
} from '@/services/requests/diagnosis';
import {
  requestGetCustomerId,
  updateCustomer
} from '@/services/requests/customer';
import { getAddress } from '@/services/requests/cep';

import { cloneObject } from '@/helpers';
import defaultData from '@/constants/defaultData';
import {
  occupationOptions,
  sexOptions,
  comunhaoOptions,
  estadoCivilOptions,
  contactOptions,
  avaliacaoNecessidadeOptions,
  stateOptions,
  educationLevelOptions,
  workSituationOptions,
  parentescoOptions,
  fumanteOptions,
  formaPagamentoOptions,
  periodicidadePagamentoOptions,
  cilindradaOptions,
  tipoDocumentoIdentificacaoOptions
} from '@/constants/options';

Vue.use(VueMask);

export default {
  name: 'ProductRealization',
  mixins: [validationMixin, createToastMixin],
  components: {
    VuePhoneNumberInput,
    ModelSelect,
    Money,
    PageHeader,
    ModalViewCommentOld,
    TitleAndDescriptionScreen,
    HeaderInformation
  },
  data() {
    return {
      diagnosis: null,
      customer: null,
      errors: {},
      hasDependentsOptions: [
        { item: true, name: 'Sim' },
        { item: false, name: 'Não' }
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      height: {
        decimal: ',',
        thousands: '.',
        suffix: ' m',
        precision: 2,
        masked: false
      },
      weight: {
        decimal: ',',
        thousands: '.',
        suffix: ' kg',
        precision: 0,
        masked: false
      },
      percent: {
        decimal: ',',
        thousands: '.',
        suffix: ' %',
        precision: 0,
        masked: false
      },
      occupationOptions,
      sexOptions,
      comunhaoOptions,
      estadoCivilOptions,
      contactOptions,
      avaliacaoNecessidadeOptions,
      stateOptions,
      educationLevelOptions,
      workSituationOptions,
      parentescoOptions,
      fumanteOptions,
      formaPagamentoOptions,
      periodicidadePagamentoOptions,
      cilindradaOptions,
      tipoDocumentoIdentificacaoOptions
    };
  },
  methods: {
    loadDiagnosis() {
      requestDiagnosis(this.diagnosisId)
        .then((res) => {
          this.diagnosis = res.data.result.data;

          if (!this.customer) {
            this.loadCustomer();
          }
        })
        .catch((error) => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },

    treatCustomerData(customer) {
      if (!customer.pessoa.endereco) {
        customer.pessoa.endereco = cloneObject(defaultData.endereco);
      }

      if (!customer.pessoa.perfil_familia) {
        customer.pessoa.perfil_familia = cloneObject(
          defaultData.perfil_familia
        );
      }

      if (!customer.pessoa.pessoa_renda) {
        customer.pessoa.pessoa_renda = cloneObject(defaultData.pessoa_renda);
      }

      if (!customer.pessoa.pessoa_renda.pessoa_renda_mensais) {
        customer.pessoa.pessoa_renda.pessoa_renda_mensais = [];
      }

      if (!customer.pessoa.pessoa_renda.pessoa_renda_anuais) {
        customer.pessoa.pessoa_renda.pessoa_renda_anuais = [];
      }

      if (!customer.pessoa.dependentes) {
        customer.pessoa.dependentes = [];
      }

      if (!customer.pessoa.beneficiarios) {
        customer.pessoa.beneficiarios = [];
      }

      if (!customer.pessoa.estudo) {
        customer.pessoa.estudo = cloneObject(defaultData.estudo);
      }

      if (!customer.pessoa.pessoa_conjuge) {
        customer.pessoa.pessoa_conjuge = cloneObject(
          defaultData.pessoa_conjuge
        );
      }

      if (!customer.pessoa.pessoa_conjuge.estudo) {
        customer.pessoa.pessoa_conjuge.estudo = cloneObject(defaultData.estudo);
      }

      if (!customer.pessoa.pessoa_conjuge.saude) {
        customer.pessoa.pessoa_conjuge.saude = cloneObject(defaultData.saude);
      }

      if (!customer.pessoa.patrimonio_investimento) {
        customer.pessoa.patrimonio_investimento = cloneObject(
          defaultData.patrimonio_investimento
        );
      }

      if (!customer.pessoa.patrimonio_investimento.patrimonio_itens) {
        customer.pessoa.patrimonio_investimento.patrimonio_itens = [];
      }

      if (!customer.pessoa.patrimonio_investimento.investimento_itens) {
        customer.pessoa.patrimonio_investimento.investimento_itens = [];
      }

      if (!customer.pessoa.pessoa_despesa) {
        customer.pessoa.pessoa_despesa = cloneObject(
          defaultData.pessoa_despesa
        );
      }

      if (!customer.pessoa.pessoa_despesa.despesa_itens) {
        customer.pessoa.pessoa_despesa.despesa_itens = [];
      }

      if (!customer.pessoa.sonhos) {
        customer.pessoa.sonhos = [];
      }

      if (!customer.pessoa.pessoa_plano) {
        customer.pessoa.pessoa_plano = cloneObject(defaultData.pessoa_plano);
      }

      if (!customer.pessoa.seguro_itens) {
        customer.pessoa.seguro_itens = [];
      }

      if (!customer.pessoa.pessoa_previdencia_privada) {
        customer.pessoa.pessoa_previdencia_privada = cloneObject(
          defaultData.pessoa_previdencia_privada
        );
      }

      if (!customer.pessoa.pessoa_previdencia_privada.previdencia_fundos) {
        customer.pessoa.pessoa_previdencia_privada.previdencia_fundos = [];
      }

      if (!customer.pessoa.pessoa_saude) {
        customer.pessoa.pessoa_saude = cloneObject(defaultData.pessoa_saude);
      }

      if (!customer.pessoa.saude) {
        customer.pessoa.saude = cloneObject(defaultData.saude);
      }

      if (!customer.pessoa.pessoa_pagamento) {
        customer.pessoa.pessoa_pagamento = cloneObject(
          defaultData.pessoa_pagamento
        );
      }

      if (!customer.pessoa.pessoa_pagamento.endereco) {
        customer.pessoa.pessoa_pagamento.endereco = cloneObject(
          defaultData.endereco
        );
      }

      if (!customer.pessoa.prioridades) {
        customer.pessoa.prioridades = [];
      }

      if (!customer.pessoa.indicacoes) {
        customer.pessoa.indicacoes = [];
      }

      if (!customer.pessoa.pessoa_remedios) {
        customer.pessoa.pessoa_remedios = [];
      }

      if (customer.pessoa.dependentes) {
        customer.pessoa.dependentes.forEach((dependent, index) => {
          if (!dependent.estudo) {
            customer.pessoa.dependentes[index].estudo = cloneObject(
              defaultData.estudo
            );
          }

          if (!dependent.saude) {
            customer.pessoa.dependentes[index].saude = cloneObject(
              defaultData.saude
            );
          }
        });
      }

      if (customer.pessoa.pessoa_data_nascimento) {
        customer.pessoa.pessoa_data_nascimento = moment(
          customer.pessoa.pessoa_data_nascimento
        ).format('YYYY-MM-DD');
      }

      if (customer.pessoa.beneficiario_data_nascimento) {
        customer.pessoa.beneficiario_data_nascimento = moment(
          customer.pessoa.beneficiario_data_nascimento
        ).format('YYYY-MM-DD');
      }

      return customer;
    },

    loadCustomer() {
      this.loading = true;
      requestGetCustomerId(this.diagnosis.pessoa.cliente.cliente_id)
        .then((res) => {
          this.loading = false;
          this.customer = this.treatCustomerData(res.data.result.data);
        })
        .catch((error) => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },

    async searchAddress(cep) {
      if (!/\d{5}-\d{3}/.test(cep)) return;

      const response = await getAddress(cep);

      this.customer.pessoa.endereco.endereco_logradouro = response.logradouro;
      this.customer.pessoa.endereco.endereco_bairro = response.bairro;
      this.customer.pessoa.endereco.endereco_estado = response.uf;
      this.customer.pessoa.endereco.endereco_cidade = response.localidade;
    },

    async searchAddressResponsavelPagamento(cep) {
      if (!/\d{5}-\d{3}/.test(cep)) return;

      const response = await getAddress(cep);

      this.customer.pessoa.pessoa_pagamento.endereco.endereco_logradouro =
        response.logradouro;
      this.customer.pessoa.pessoa_pagamento.endereco.endereco_bairro =
        response.bairro;
      this.customer.pessoa.pessoa_pagamento.endereco.endereco_estado =
        response.uf;
      this.customer.pessoa.pessoa_pagamento.endereco.endereco_cidade =
        response.localidade;
    },

    handleFileUpload(document) {
      if (!document.file) return;

      uploadDocumentFile(document.diagnostico_documento_id, document.file)
        .then(() => {
          this.loadDiagnosis();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleRejectedFilesUpload() {
      const rejectedDocuments = this.rejectedDocuments.filter(
        (document) => document.file
      );

      if (rejectedDocuments.length === 0) return;

      const promises = [];

      rejectedDocuments.forEach((document) => {
        const promise = uploadDocumentFile(
          document.diagnostico_documento_id,
          document.file
        );
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          this.loadDiagnosis();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleAddDependent() {
      this.customer.pessoa.dependentes.push({
        nome_dependente: '',
        sexo_dependente: 'M',
        cpf_dependente: null,
        rg_dependente: null,
        data_nasc_dependente: null
      });
    },

    handleAddBeneficiary() {
      this.customer.pessoa.beneficiarios.push({
        nome_beneficiario: null,
        cpf_beneficiario: null,
        rg_beneficiario: null,
        percentual_beneficiario: 0,
        data_nascimento_beneficiario: null,
        grau_parentesco_beneficiario: null,
        grau_parentesco_customizado_beneficiario: null
      });
    },

    saveCustomerData() {
      const data = cloneObject(this.customer.pessoa);

      const customerId = this.diagnosis.pessoa.cliente.cliente_id;

      return updateCustomer(customerId, data);
    },

    handleSave() {
      this.saveCustomerData()
        .then(() => {
          this.loadCustomer();
          this.createToast(
            'CLIENTE SALVO COM SUCESSO.',
            'Sucesso !',
            'success'
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleSendData() {
      this.saveCustomerData()
        .then(() => {
          const diagnosisId = this.diagnosis.diagnostico_id;

          const data = {
            estagio_id: 5
          };

          if (!this.diagnosis.enviado_especialista) {
            data.enviado_especialista = confirm(
              'Deseja enviar para o especialista?'
            );
          }

          if (
            this.diagnosis.diagnostico_historico.estagio_id >= data.estagio_id
          ) {
            this.$router.push({ name: 'AwaitingSpecialist' });
            return;
          }

          updatePhase(diagnosisId, data)
            .then(() => {
              this.$router.push({ name: 'AwaitingSpecialist' });
            })
            .catch(() => {
              this.$store.state.modal.isOpenModalErrorRequest = true;
            });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    viewSpecialistComment(document) {
      this.$store.state.modal.commentModalViewComment =
        document.diagnostico_comentario_especialista.comentrario;
      this.$store.state.modal.isOpenModalViewComment = true;
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} arquivos selecionados`;
    },
    addRemedio() {
      this.customer.pessoa.pessoa_remedios.push(
        cloneObject(defaultData.pessoa_remedio)
      );
    },
    handleDeleteRemedio(index) {
      this.customer.pessoa.pessoa_remedios.splice(index, 1);
    },
    handleDeleteFile(document) {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteDocumentFile(document.diagnostico_documento_id)
        .then((res) => {
          const documentIndex = this.diagnosis.diagnostico_documentos.findIndex(
            (diagnosisDocument) =>
              diagnosisDocument.diagnostico_documento_id ===
              document.diagnostico_documento_id
          );

          this.$set(
            this.diagnosis.diagnostico_documentos,
            documentIndex,
            res.data.result.data
          );
        })
        .catch(() => {
          alert('Houve um erro ao excluir o arquivo');
        });
    },
    handleDeleteDependent(index) {
      this.customer.pessoa.dependentes.splice(index, 1);
    },
    handleDeleteBeneficiary(index) {
      this.customer.pessoa.beneficiarios.splice(index, 1);
    },
    addIndicacao() {
      this.customer.pessoa.indicacoes.push(cloneObject(defaultData.indicacao));
    },
    handleDeleteIndication(index) {
      this.customer.pessoa.indicacoes.splice(index, 1);
    }
  },
  computed: {
    diagnosisId() {
      return this.$route.params.diagnosisId;
    },
    documentsWaiting() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 0
      );
    },
    verifiedDocuments() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 1
      );
    },
    rejectedDocuments() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 2
      );
    },
    breadcrumb() {
      const customerId = this.diagnosis
        ? this.diagnosis.pessoa.cliente.cliente_id
        : null;

      return [
        {
          text: 'Dashboards',
          href: '/'
        },
        {
          text: 'Gerar estudo completo',
          href: `/diagnosis/complete/${customerId}`
        },
        {
          text: 'Direcionamento do consultor',
          href: `/diagnosis/complete/consultant-direction/${this.diagnosisId}`
        },
        {
          text: 'Resultado do estudo',
          href: `/diagnosis/complete/consultant-direction/${this.diagnosisId}/exam-result`
        },
        {
          text: 'Receita/Cotação',
          href: `/diagnosis/complete/consultant-direction/${this.diagnosisId}/quotation-result`
        },
        {
          text: 'Efetivação dos produtos',
          active: true
        }
      ];
    }
  },
  mounted() {
    this.loadDiagnosis();
  }
};
</script>

<style lang="scss">
.hrdivider {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  text-align: left;
}

.hrdivider span {
  position: absolute;
  top: -11px;
  background: #fff;
  font-weight: bold;
  font-size: 16px;
}
</style>
